import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  isAnimationPlated: boolean;
}

const initialState: AppState = {
  isAnimationPlated: false,
};

const appStateSlice = createSlice({
  name: 'AppState',
  initialState,
  reducers: {
    toggleIsAnimationPlayed: (state) => {
      state.isAnimationPlated = true;
    },
  },
});

export const { toggleIsAnimationPlayed } = appStateSlice.actions;

export default appStateSlice.reducer;
