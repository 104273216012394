import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { display_type } from '@/type';

const initialState: display_type[] = Array.from({ length: 256 }).map(() => ({
  status: 'empty_task',
  data: '00',
  key: `${Date.now()}-${Math.random().toString(36).slice(2)}`,
}));

const nftRendererSlice = createSlice({
  name: 'nftRenderer',
  initialState,
  reducers: {
    setDisplayValues: (state, action: PayloadAction<display_type[]>) => {
      return action.payload;
    },
  },
});

export const { setDisplayValues } = nftRendererSlice.actions;

export default nftRendererSlice.reducer;
