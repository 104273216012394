const SERVER_URL = process.env.REACT_APP_SERVER_URL;

async function apiRequest(endpoint: string, options: RequestInit = {}) {
  const authToken = localStorage.getItem("auth");

  const headers = {
    "Content-Type": "application/json",
    ...options.headers,
    ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
  };

  try {
    const response = await fetch(`${SERVER_URL}/${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      // 根據狀態碼處理錯誤
      const errorDetails = await response.json(); // 解析錯誤訊息（如果有）
      throw new Error(errorDetails.message || `HTTP Error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
    throw error;
  }
}

// 獲取當前用戶
export const getUser = () => apiRequest("me");

// 獲取任務列表
export const getTasks = () => apiRequest("tasks");

// 提交回答
export const answerQuestion = (
  index: number,
  selected: number,
  responseTime: number
) =>
  apiRequest(`questions/${index}`, {
    method: "POST",
    body: JSON.stringify({ selected, responseTime }),
  });

// 獲取問題列表
export const getQuestions = () => apiRequest("questions");

// 獲取簽名
export const getSignature = () => apiRequest("twin3/signature");

// 獲取排行榜
export const getLeaderboard = (offset: number) =>
  apiRequest(`leaderboard/${offset.toString(16)}`);

// 創建用戶
export const createUser = (provider: {
  type?: string;
  address?: string;
  signature?: string | null;
  accessToken?: string | null;
  referrer?: string;
}) => apiRequest("twin3", { method: "POST", body: JSON.stringify(provider) });

export const refreshSBT = () =>
  apiRequest(`refreshSBT`, {
    method: "POST",
    body: JSON.stringify({}),
  });

// 驗證 Recaptcha
export const verifyRecaptcha = (
  account: string,
  responses: { v2: string; v3: string }
) =>
  apiRequest(`twin3/${account}/verification/recaptcha`, {
    method: "POST",
    body: JSON.stringify(responses),
  });

// 發送 SMS
export const sendSMSMessage = (
  account: string,
  countryCode: string,
  phone: string,
) =>
  apiRequest(`twin3/${account}/sms`, {
    method: "POST",
    body: JSON.stringify({ phone: `${countryCode}${phone}` }),
  });

// 驗證 SMS
export const verifySMSMessage = (account: string, code: string) =>
  apiRequest(`twin3/${account}/verification/sms`, {
    method: "POST",
    body: JSON.stringify({ code }),
  });

// 驗證 OAuth
export const verifyOauthResponse = (
  provider: string,
  account: string,
  token: string
) =>
  apiRequest(`twin3/${account}/verification/oauth`, {
    method: "POST",
    body: JSON.stringify({ provider, token }),
  }).then((response) => {
    // 檢查是否包含 redirect_url
    if (response.redirect_url) {
      return response.redirect_url;
    } else {
      return null; // 無 redirect_url 時回傳 null
    }
  });

// 註冊用戶 OTP
export const Twin3registerUser = (contactInfo: {
  source?: string;
  source_user_id?: string;
}) =>
  apiRequest("vendor/register", {
    method: "POST",
    body: JSON.stringify(contactInfo),
  });

// 生成 OTP
export const Twin3generateOtp = (
  token: string,
  contactInfo: { source?: string; twin3_user_id?: string }
) =>
  apiRequest("vendor/generateOTP", {
    method: "POST",
    headers: { "x-token": token },
    body: JSON.stringify(contactInfo),
  });

// 驗證 OTP
export const Twin3verifyOtp = (otp: string) =>
  apiRequest("vendor/verifyOTP", {
    method: "POST",
    body: JSON.stringify({ otp }),
  });

// 初始化人類驗證
export const initiateHumanVerification = (
  address: string,
  redirectUrl: string
) =>
  apiRequest(`twin3/${address}/verification/oauth`, {
    method: "POST",
    body: JSON.stringify({ redirect_url: redirectUrl }),
  }).then((data) => data.redirect_url);

// 獲取供應商資訊
export const getSupplierInformation = (twin3UserId: string) =>
  apiRequest(`vendor/info:${twin3UserId}`, { method: "GET" });
