import { useEffect, useState } from 'react';
import { useAppDispatch } from '../state/hooks';
import { toggleIsAnimationPlayed } from '../state/appState/appStateSlice';

const MatrixPreloader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Simulate window load event
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    setTimeout(() => {
      dispatch(toggleIsAnimationPlayed());
    }, 6000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Generate matrix characters when component mounts
    const matrixContainer = document.querySelector('.matrix');
    if (!matrixContainer) return;

    const columns = Math.floor(window.innerWidth / 20);
    const rows = Math.floor(window.innerHeight / 20);

    const generateRandomCharacter = () => {
      const characters = 'ABCDEF0123456789*';
      return characters.charAt(Math.floor(Math.random() * characters.length));
    };

    // Create matrix spans
    for (let i = 0; i < columns; i++) {
      for (let j = 0; j < rows; j++) {
        const span = document.createElement('span');
        span.style.left = `${i * 20}px`;
        span.style.top = `${j * 20}px`;
        span.style.animationDelay = `${Math.random() * 5}s`;
        span.textContent = generateRandomCharacter();
        matrixContainer.appendChild(span);
      }
    }

    // Cleanup function
    return () => {
      if (matrixContainer) {
        matrixContainer.innerHTML = '';
      }
    };
  }, []);

  return (
    <div
      id="preloader"
      className={`fixed inset-0 flex justify-center items-center z-[9999] bg-[#0a0a0a] overflow-hidden ${!isLoading ? 'opacity-0 invisible' : 'opacity-100'}`}
      style={{
        transition: 'opacity 0.5s ease, visibility 0.5s ease'
      }}
    >

      <div className="matrix absolute inset-0 z-[1] overflow-hidden bg-transparent flex flex-wrap">
        {/* Matrix spans will be dynamically added here */}

      </div>
      <img
        src="/assets/img/logo.svg"
        alt="Loading..."
        className="relative z-10 w-16 h-16 animate-spin"
        style={{ animation: 'spin 1s linear infinite' }}
      />

    </div>
  );
};

export default MatrixPreloader;