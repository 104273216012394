import { useContext, useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import AccountContext from "@/context/account";
import NFTRenderer from "@/components/NFTRenderer";
import Countdown from "@/components/Countdown";
import TaskNavigator from "@/components/TaskNavigator";
import RankingPanel from "@/pages/RankingPanel";
import useUpdateSBT from "@/libs/useUpdateSBT";
import useNFTData from "@/hooks/useNFTData";
import { getSignature } from "@/api";
import { useReadContract } from "wagmi";
import {
  Twin3Genesis_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
} from "@/utils/constants";
import GENESIS_ABI from "@/abi/Twin3Genesis _abi.json";
import USDT_ABI from "@/abi/USDT_abi.json";
import { useToast } from "@chakra-ui/react";
import { convertToUSDTUnits, isValidNumber } from "@/utils/web3Helper";

const DNA = () => {
  const { displayValues, userData } = useNFTData();
  const { account, accountInfo, id } = useContext(AccountContext);
  const [inputValue, setInputValue] = useState(0);
  const { submit } = useUpdateSBT(userData, Number(inputValue));
  const [canSubmit, setCanSubmit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const { refetch: refetchTotalSupply } = useReadContract({
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: GENESIS_ABI,
    functionName: "totalSupply",
    args: [id],
  });

  const { refetch: refetchMaxSupply } = useReadContract({
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: GENESIS_ABI,
    functionName: "maxSupply",
    args: [id],
  });

  const { data: balance, refetch: refetchBalance } = useReadContract({
    address: USDT_CONTRACT_ADDRESS,
    abi: USDT_ABI,
    functionName: "balanceOf",
    args: [account],
  });

  const handleSubmit = useCallback(async () => {
    await refetchBalance();
    await refetchTotalSupply();
    await refetchMaxSupply();

    if (inputValue === 0) {
      toast({
        title: "Please input USDT balance!",
        status: "error",
      });
      return;
    }

    if (parseInt(balance as string) < convertToUSDTUnits(Number(inputValue))) {
      toast({
        title: "Not enough USDT to approve!",
        status: "error",
      });
      return;
    } else {
      if (!canSubmit) {
        return;
      }
      setCanSubmit(false);
      const response = await getSignature();
      await submit(response.signature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmit, submit]);

  // useEffect(() => {
  //   console.log("displayValues", displayValues);
  //   console.log("userData", userData);
  //   console.log("taskData", taskData);
  // }, [displayValues, userData, taskData]);


  return (
    <Flex py={20} justify={"center"} gap={"80px"}>
      {/* 左邊導航欄 */}
      <TaskNavigator topParam={60} />
      {/* 右邊部分 */}
      <Flex
        flexDirection="column"
        mb={20}
        maxW={"750px"}
        p={{ base: 2, md: 0 }}
      >
        <VStack align="flex-start" mb={5} w={"full"} className={"rounded-lg border border-[#27272A] shadow-sm p-2"}>
          <Text fontSize={"xx-large"} fontWeight={"bold"}>
            Me
          </Text>
          <Text fontWeight={300}>
            The true you in different dimensions, staying transparent yet
            mysterious.
          </Text>
          <Box
            bg="black"
            color="white"
            p={4}
            borderRadius="md"
            position="relative"
          >
            <Text fontWeight={300} className={"rounded-lg border border-[#27272A] shadow-sm p-2"}>
              Attribute values and rankings are hidden in the black number for
              the airdrop index. Update more attributes to get more airdrops.{" "}
              <Link
                color="white"
                href="https://whitepaper.0xer.org"
                target="_blank"
                textDecoration="underline"
              >
                more
              </Link>
            </Text>
            {/* 下方三角形 */}
            <Box position="absolute" bottom="-15px" right="2rem" zIndex={-1}>
              <svg
                width="48"
                height="56"
                viewBox="0 0 48 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.1731 55.3933C45.6805 56.4985 47.7451 55.1159 47.2974 53.3013L34.5446 1.61758C34.2122 0.270603 32.6428 -0.337059 31.49 0.434873L1.31108 20.6431C0.158284 21.4151 0.122452 23.0976 1.24134 23.9179L44.1731 55.3933Z"
                  fill="#000100"
                />
              </svg>
            </Box>
          </Box>
        </VStack>
        <RankingPanel displayValues={displayValues} />
        <NFTRenderer
          values={accountInfo?.data}
          size={{ base: "100%", lg: 750 }}
        />
        <VStack align="flex-start" mb={5} w={"100%"}>
          {/* upgradeMyTwin section start */}
          <Flex
            mt={5}
            w={"100%"}
            justify={"space-between"}
            direction={{ base: "column", md: "row" }}
            gap={{ base: 2, md: 0 }}
            className={"rounded-lg border border-[#27272A] shadow-sm p-2"}
          >
            <Text fontSize={"15px"} fontWeight={220}>
              Already completed some new tasks?
              <br />
              Want to publish the new version of your twin?
            </Text>
            <Button
              isDisabled={!canSubmit}
              color={isOpen ? "black" : "white"}
              gap={3}
              onClick={() => setIsOpen(!isOpen)}
              // bg={'#F7F7F7'}
              bg={isOpen ? "#F7F7F7" : "orange"}
            >
              Upgrade My Twin
              <svg
                width="17"
                height="10"
                viewBox="0 0 17 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.61144 9.19617L0.540194 2.12492L2.30769 0.357421L8.4952 6.54492L14.6827 0.357422L16.4502 2.12492L9.37894 9.19617C9.14454 9.43051 8.82665 9.56215 8.49519 9.56215C8.16374 9.56215 7.84585 9.43051 7.61144 9.19617Z"
                    fill="black"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.61144 9.19617L0.540194 2.12492L2.30769 0.357421L8.4952 6.54492L14.6827 0.357422L16.4502 2.12492L9.37894 9.19617C9.14454 9.43051 8.82665 9.56215 8.49519 9.56215C8.16374 9.56215 7.84585 9.43051 7.61144 9.19617Z"
                    fill="white"
                  />
                )}
              </svg>
              {canSubmit || (
                <>
                  (
                  <Countdown from={10} onFinish={() => setCanSubmit(true)} />)
                </>
              )}
            </Button>
          </Flex>
          {/* upgradeMyTwin section  end */}
          {isOpen ? (
            <Flex
              bg="black"
              w={{ base: "100%", md: "100%" }}
              h={{ base: "auto", md: "300px" }}
              p={{ base: 4, md: 5 }}
              m={{ base: "auto", md: 0 }}
              borderRadius="md"
              className={"rounded-lg border border-[#27272A] shadow-sm p-2"}
            >
              <Flex
                w={"100%"}
                flexDirection={"column"}
                gap={{ base: 3, md: 5 }}
              >
                <Flex
                  w={"100%"}
                  justify={"space-between"}
                  align={"center"}
                  gap={{ base: 2, md: 5 }}
                >
                  <VStack>
                    <Text
                      as={"h1"}
                      fontWeight={"semibold"}
                      fontSize={{ base: "20px", md: "33px" }}
                    >
                      1/Input
                    </Text>
                    <Text
                      fontWeight={135}
                      fontSize={{ base: "10px", md: "12px" }}
                    >
                      Ensure your wallet has enough amount. Go with the amount
                      you're cool with.
                    </Text>
                  </VStack>
                  <Flex fontSize={{ base: "3xl", md: "5xl" }}>➔</Flex>
                  <VStack>
                    <Text
                      as={"h1"}
                      fontWeight={"semibold"}
                      fontSize={{ base: "20px", md: "33px" }}
                    >
                      2/Confirm
                    </Text>
                    <Text
                      fontWeight={135}
                      fontSize={{ base: "10px", md: "12px" }}
                    >
                      Authorization on Smartwallet for on-chain transactions.
                      Only two steps.
                    </Text>
                  </VStack>
                </Flex>
                <Flex gap={5} maxW={"80%"} h={"50px"} align={"center"} >
                  <Box
                    w={{ base: "40px", md: "50px" }}
                    h={{ base: "40px", md: "50px" }}
                  >
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 45 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 36.6406L8.5 14.6406L2.5 14.6406L2.5 36.6406L8.5 36.6406Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="4"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 14.6402C15.6227 8.12085 19.7507 4.38019 20.884 3.41819C22.584 1.97519 24.504 2.58119 24.504 6.19319C24.504 9.80519 19.219 11.8882 19.219 14.6402C19.215 14.6569 25.974 14.6579 39.496 14.6432C39.8901 14.6427 40.2805 14.7198 40.6448 14.8702C41.0091 15.0206 41.3402 15.2413 41.6192 15.5197C41.8982 15.7982 42.1195 16.1288 42.2707 16.4928C42.4218 16.8568 42.4997 17.2471 42.5 17.6412L42.5 17.6442C42.5001 18.0388 42.4225 18.4295 42.2716 18.7941C42.1207 19.1587 41.8995 19.4899 41.6205 19.769C41.3415 20.048 41.0103 20.2694 40.6458 20.4204C40.2813 20.5715 39.8906 20.6492 39.496 20.6492L31.486 20.6492C30.278 28.6212 29.6113 32.9555 29.486 33.6522C29.298 34.6972 28.3 36.6402 25.432 36.6402L8.5 36.6402L8.5 14.6402Z"
                        stroke="white"
                        strokeWidth="4"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                  <InputGroup>
                    <Input
                      placeholder="Enter amount"
                      bgColor="black"
                      color="white"
                      border="2px solid white"
                      borderRadius="0"
                      h={{ base: "40px", md: "50px" }}
                      fontSize={{ base: "sm", md: "md" }}
                      value={inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || isValidNumber(value)) {
                          setInputValue(Number(value));
                        }
                      }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      color="white"
                      h="100%"
                      mr={4}
                      fontSize={{ base: "xs", md: "sm" }}
                    >
                      USDT
                    </InputRightElement>
                  </InputGroup>
                  <Button
                    bgColor={"black"}
                    color={"white"}
                    border={"2px solid white"}
                    h={{ base: "40px", md: "50px" }}
                    px={{ base: "20px", md: "30px" }}
                    fontSize={{ base: "sm", md: "md" }}
                    onClick={handleSubmit}
                    isDisabled={!canSubmit}
                  >
                    UPDATE
                  </Button>
                </Flex>
                <Text fontWeight={135} fontSize={{ base: "10px", md: "12px" }}>
                  Your will get{" "}
                  <Text as="span" color={"orange"} mx={1}>
                    {inputValue}
                  </Text>{" "}
                  points after the on-chain upgrade and payment is successful.
                </Text>
                <Text fontWeight={135} fontSize={{ base: "10px", md: "12px" }}>
                  ＊For every 1 USDT you spend on updating your agent, earn 1
                  Point. ＊Accumulate points to redeem limited-edition Genesis
                  NFTs, exclusively for early supporters.
                </Text>
              </Flex>
            </Flex>
          ) : null}

          <Box
            bg="black"
            color="white"
            p={4}
            borderRadius="md"
            position="relative"
            mt={5}
            className={"rounded-lg border border-[#27272A] shadow-sm p-2"}
          >
            <Text fontWeight={300}>
              The AI Agent has detected updated information. It's time to go
              on-chain to earn more rewards.{" "}
              <Link
                color="white"
                href="https://whitepaper.0xer.org"
                target="_blank"
                textDecoration="underline"
              >
                more
              </Link>
            </Text>
            {/* 下方三角形 */}
            <Box position="absolute" bottom="-15px" right="2rem" zIndex={-1}>
              <svg
                width="48"
                height="56"
                viewBox="0 0 48 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.1731 55.3933C45.6805 56.4985 47.7451 55.1159 47.2974 53.3013L34.5446 1.61758C34.2122 0.270603 32.6428 -0.337059 31.49 0.434873L1.31108 20.6431C0.158284 21.4151 0.122452 23.0976 1.24134 23.9179L44.1731 55.3933Z"
                  fill="#000100"
                />
              </svg>
            </Box>
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default DNA;
