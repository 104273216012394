export const Twin3_CONTRACT_ADDRESS = process.env
  .REACT_APP_Twin3_CONTRACT_ADDRESS as `0x${string}`;

export const Twin3Genesis_CONTRACT_ADDRESS = process.env
  .REACT_APP_Twin3Genesis_CONTRACT_ADDRESS as `0x${string}`;

export const Twin3ScoreExchange_CONTRACT_ADDRESS = process.env
  .REACT_APP_Twin3ScoreExchange_CONTRACT_ADDRESS as `0x${string}`;

export const USDT_CONTRACT_ADDRESS = process.env
  .REACT_APP_USDT_CONTRACT_ADDRESS as `0x${string}`;

export const RPC = process.env.REACT_APP_RPC || '';

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10);
